import React, {useCallback, useMemo, useState} from 'react';
import {shortenAddress, useEthers, useLookupAddress} from '@usedapp/core';
import makeBlockie from 'ethereum-blockies-base64';
import Link from 'next/link';
import {useScopeTranslation} from '@everdome_io/next-js-helper';

import ExpandMoreIcon from '~assets/download/icons/expandmore.svg';
import Avatar from '~components/download/atoms/Avatar';
import useDisconnectWallet from '~components/download/atoms/ConnectWalletButtons/useDisconnectWallet';
import {Routes} from '~consts/routes';

import {
    DisconectButton,
    DisconectContainer,
    FlexContainer,
    Icon,
    StyledDetails,
    StyledSummary,
    SummaryContent,
} from './styles';

const Account = () => {
    const {account} = useEthers();
    const {ens} = useLookupAddress(account);

    const truncatedAddress = useMemo(
        () => (account ? ens ?? shortenAddress(account) : ''),
        [account, ens],
    );

    const avatarSource = useMemo(
        () => (account ? makeBlockie(account) : undefined),
        [account],
    );
    const [openDropdown, setOpenDropdown] = useState(false);

    const handleAccordionChange = useCallback(() => {
        setOpenDropdown((val) => !val);
    }, [setOpenDropdown]);

    const disconnect = useDisconnectWallet();

    const handleDisconnectWallet = useCallback(() => {
        setOpenDropdown(false);
        disconnect();
    }, [setOpenDropdown, disconnect]);

    const t = useScopeTranslation('game-download', 'account');
    return !account ? (
        <Avatar alt={t('avatar')} />
    ) : (
        <StyledDetails open={openDropdown} onClick={() => handleAccordionChange()}>
            <StyledSummary>
                <SummaryContent>
                    <FlexContainer>
                        <div>{`${truncatedAddress}`}</div>
                        <div>
                            <Avatar alt={t('avatar')} src={avatarSource} />
                        </div>
                    </FlexContainer>
                </SummaryContent>
                <Icon isOpen={openDropdown}>
                    <ExpandMoreIcon />
                </Icon>
            </StyledSummary>
            <DisconectContainer>
                <Link href={Routes.Profile} passHref>
                    {t('profile')}
                </Link>
                <DisconectButton
                    variant="contained"
                    text={t('disconnect')}
                    onClick={handleDisconnectWallet}
                />
            </DisconectContainer>
        </StyledDetails>
    );
};
export default Account;

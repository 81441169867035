import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    border: 1px solid #000000;
    border-radius: 50%;
`;

export const StyledAvatar = styled.div`
    width: 48px;
    height: 48px;
    border: 1px solid #000000;
    background: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    img {
        position: absolute;
        inset: 0 0 0 0;
        width: 100%;
    }
    div {
        margin-top: 17px;
    }
`;

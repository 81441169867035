import styled from 'styled-components';

import Button from '~components/download/atoms/Button';

export const Input = styled.input`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    width: 280px;
    line-height: normal;
    padding: 11px 22px;
`;

export const StyledButton = styled(Button)`
    width: 280px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
`;

import React from 'react';

import User from '~assets/download/icons/user.svg';

import {Container, StyledAvatar} from './styles';

type AvatarProps = {
    alt: string;
    src?: string;
};
const Avatar = ({alt, src}: AvatarProps) => {
    return (
        <Container>
            <StyledAvatar>
                {src && <img alt={alt} src={src} />}
                <div>
                    <User />
                </div>
            </StyledAvatar>
        </Container>
    );
};
export default Avatar;

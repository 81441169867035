import {useMutation, useQuery} from 'react-query';

import useFetch from '~api/useFetch';
import env from '~utils/env';
import {AuthMeData, AuthMeResponse} from '~types/Auth';
import {useJwtToken} from '~components/providers/AuthProvider';
import {IdmApiRoutes} from '~consts/routes';

export default function useFetchMe(enabled = false, showError = true) {
    const {apiGet} = useFetch();
    const {token} = useJwtToken();

    return useQuery<AuthMeResponse>(
        IdmApiRoutes.Me,
        () =>
            apiGet(IdmApiRoutes.Me, {
                baseUrl: env('IDM_API'),
                withJwtToken: true,
            }),
        {
            enabled: Boolean(enabled && token),
            ...(!showError && {
                onError() {},
            }),
            retry: false,
        },
    );
}

export function usePutMe() {
    const {apiPut} = useFetch();

    return useMutation<AuthMeResponse, {}, AuthMeData>(IdmApiRoutes.Me, (data) =>
        apiPut(IdmApiRoutes.Me, {
            baseUrl: env('IDM_API'),
            withJwtToken: true,
            data,
        }),
    );
}

import {useState} from 'react';
import {useScopeTranslation} from '@everdome_io/next-js-helper';

import useFetchMe, {usePutMe} from '~api/useFetchMe';
import {showError, showSuccess} from '~utils/toast';
import {ApiErrorType} from '~types/api';
import {Loader} from '~components/download/atoms/Loader';

import {Input, StyledButton} from './styles';

const ChangeNameForm = ({onSuccess}: {onSuccess?: () => void}) => {
    const {data, isLoading, refetch} = useFetchMe(true, true);
    const [loading, setLoading] = useState(false);
    const {mutateAsync} = usePutMe();
    const onSave = async (value: string) => {
        try {
            setLoading(true);
            await mutateAsync({username: value});
            await refetch();
            showSuccess(t('usernameSuccess'));
            onSuccess && onSuccess();
        } catch (e) {
            showError((e as ApiErrorType).message);
        }
        setLoading(false);
    };
    const [tempText, setTempText] = useState('');
    const t = useScopeTranslation('game-download', 'profile');
    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Input
                value={tempText}
                placeholder={data?.username}
                onChange={({target}) => setTempText(target.value)}
                disabled={loading}
            />
            <StyledButton
                variant="contained"
                disabled={loading}
                onClick={() => onSave(tempText)}
                loading={loading}
            >
                {t('save')}
            </StyledButton>
        </>
    );
};

export default ChangeNameForm;

import styled from 'styled-components';

import Button from '~components/download/atoms/Button';

type StyledButtonProps = {
    loading?: boolean;
};

export const StyledButton = styled(Button)<StyledButtonProps>`
    width: 280px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
`;

export const StyledDetails = styled.details`
    align-items: center;
    justify-content: center;
    position: relative;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    color: inherit;
    display: flex;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: transparent;
    cursor: pointer;
`;

export const StyledSummary = styled.summary`
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
`;

export const SummaryContent = styled.div`
    display: flex;
    flex: 1;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-right: 5px;

    & > div:nth-of-type(1) {
        flex: 1;
        text-align: right;
        font-size: 1.0714285714285714rem;
        line-height: 1.6;
        font-weight: 600;
        margin-right: 10px;
    }
`;

type IconProps = {
    isOpen: boolean;
};

export const Icon = styled.div<IconProps>`
    display: flex;
    color: rgba(0, 0, 0, 0.54);
    transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const DisconectContainer = styled.div`
    position: absolute;
    bottom: 0;
    height: auto;
    transform: translateY(100%);
    overflow: visible;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
    padding-top: 16px;
`;

export const DisconectButton = styled(Button)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 16px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
        0px 1px 5px 0px rgb(0 0 0 / 12%);
    text-transform: initial;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
`;

import styled from 'styled-components';

import Button from '~components/download/atoms/Button';

export const Header = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    justify-content: flex-end;
    display: flex;
    padding: 36px 80px;
`;

export const LogoContainer = styled.div`
    margin-bottom: 25px;
    @media (min-height: 550px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 161px;
        margin-bottom: 0;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    position: relative;
    padding-top: 152px;
    align-items: center;
    flex: 1;
    height: 100%;
    justify-content: center;
`;

export const TitleContainer = styled.div`
    position: relative;
    margin-bottom: 60px;
    @media (min-height: 550px) {
        margin-top: -152px;
    }
`;
export const Content = styled.div`
    position: relative;
    background: #f6f6f6;
    display: inline-block;
    z-index: 1;
    padding: 0 24px;
`;
export const ButtonContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

type StyledButtonProps = {
    loading?: boolean;
};

export const StyledButton = styled(Button)<StyledButtonProps>`
    width: 280px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
`;

export const Line = styled.div`
    height: 1px;
    width: 100vw;
    background: black;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50vw);
`;
export const Title = styled.p`
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    margin: 0;
    background: #f6f6f6;
    display: block;
    position: relative;
    z-index: 1;
    padding: 0 24px;
`;
export const FlexItem = styled.div`
    padding: 16px 0 0 16px;
`;

type IconProps = {
    isOpen: boolean;
};

export const Icon = styled.div<IconProps>`
    display: flex;
    color: rgba(0, 0, 0, 0.54);
    transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

import React from 'react';

import Account from '~components/download/molecules/Account';
import Logo from '~assets/download/logo.svg';

import {
    ButtonContainer,
    Container,
    Content,
    Header,
    Line,
    LogoContainer,
    Title,
    TitleContainer,
} from './styles';

const TopPage = ({
    title,
    children,
    contentLine,
    titleLine,
}: {
    title: string;
    children?: React.ReactNode;
    titleLine?: boolean;
    contentLine?: boolean;
}) => {
    return (
        <Container>
            <Header>
                <div>
                    <Account />
                </div>
            </Header>
            <LogoContainer>
                <Logo />
            </LogoContainer>
            <TitleContainer>
                <Title>{title}</Title>
                {titleLine && <Line />}
            </TitleContainer>
            <ButtonContainer>
                <Content>{children}</Content>
                {contentLine && <Line />}
            </ButtonContainer>
        </Container>
    );
};
export default TopPage;
